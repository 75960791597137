import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import logo from '../assets/logo.png';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMapMarkerAlt, faEnvelope, faPhone , faGlobe, faMobileAlt, faPalette} from "@fortawesome/free-solid-svg-icons";
import { faInstagram, faFacebook, faYoutube, faLinkedin, faTwitter } from "@fortawesome/free-brands-svg-icons";
import webAppImage from '../assets/JKOJ.jpg';

const NavBar = () => {
  const [isHovering, setIsHovering] = useState(false);
  const [isHovering1, setIsHovering1] = useState(false);

  const [isHoverContentHovering, setIsHoverContentHovering] = useState(false);
  const [isHoverContentHovering1, setIsHoverContentHovering1] = useState(false);

  const handleHoverIn = () => {
    setIsHovering(true);
  };

  const handleHoverOut = () =>{
    setTimeout(() => {
      setIsHovering(false);
    }, 500); 
  }

 
  const handleHoverIn1 = () => {
    setIsHovering1(true);
  };

  const handleHoverOut1 = () => {
     setTimeout(() => {
      setIsHovering1(false);
    }, 300); 
  };



  return (
    <>
      

      <header className="navbar flex justify-between items-center text-white border-b px-6 sticky top-0 z-10" style={{ backgroundColor: "rgb(1, 6, 27)"}}>
        <div className="flex items-center">
          <img src={logo} alt="Logo" className="logo mt-2" style={{ height: '70px' }} />
        </div>
        <ul className="flex font-serif ">
        <Link to="/"><li className='p-4 hover:border-b cursor-pointer'><b>Home</b></li></Link>
          <Link to="/services" >  <li className='p-4 hover:border-b cursor-pointer' onMouseEnter={handleHoverIn} onMouseLeave={handleHoverOut} onClick={() => setIsHovering(false)}>
          
              <b >Services</b>
           
          </li> </Link>
          <Link to="/technologies" ><li className='p-4 hover:border-b cursor-pointer'><b>Technologies</b></li></Link>
          <li className='p-4 hover:border-b cursor-pointer' onMouseEnter={handleHoverIn1} onMouseLeave={handleHoverOut1} onClick={() => setIsHovering1(false)}><Link to="/products" ><b>Products</b></Link></li>
          <li className='p-4 hover:border-b cursor-pointer'><Link to="/contact"><b>Contact</b></Link></li>
        </ul>
      </header>
      {/* <header className="flex justify-between items-center py-1 px-6  top-0 z-10" style={{ boxShadow: 'inset 0 0 10px 0px rgba(0, 0, 0, 1)', fontFamily: 'serif' }}>
        <div className="flex  mb-2">
          <div className="flex items-center mr-4">
            <FontAwesomeIcon icon={faMapMarkerAlt} className="mr-1" />
            <span>K.K.Nagar, Madurai-625020</span>
          </div>
          <div className="flex items-center mr-4">
            <FontAwesomeIcon icon={faPhone} className="mr-1" />
            <span>+91 6369070815</span>
          </div>
          <div className="flex items-center">
            <FontAwesomeIcon icon={faEnvelope} className="mr-1" />
            <span>info@fiveytech.com</span>
          </div>
        </div>
        <div className="flex items-center">
          <FontAwesomeIcon icon={faInstagram} className="w-10 h-6 mr-2" />
          <FontAwesomeIcon icon={faFacebook} className="w-10 h-6 mr-2" />
          <FontAwesomeIcon icon={faYoutube} className="w-10 h-6 mr-2" />
          <FontAwesomeIcon icon={faLinkedin} className="w-10 h-6 mr-2" />
          <FontAwesomeIcon icon={faTwitter} className="w-10 h-6 " />
        </div>
      </header> */}

      {(isHovering || isHoverContentHovering) && (
      <div 
      className="hover-content text-center sticky z-10 ml-10 mr-10" 
      style={{top:'79px'}}
      onMouseEnter={() => setIsHoverContentHovering(true)}
      onMouseLeave={() => setIsHoverContentHovering(false)}
  >
      <div className="p-14 text-center absolute z-50 top-30 w-full bg-white grid grid-cols-2" style={{ boxShadow: 'inset 0 0 10px 5px rgba(0, 0, 0, 1)', fontFamily: 'serif' }}>
          <div className=''>
              <img src={webAppImage} alt="Your Image"  />
          </div>
          <div className='pt-8'>
    <h2 className="text-3xl font-bold mb-2">Our Services</h2>
    <br/>
    <p className="text-gray-700 hover:text-blue-600" onClick={() => setIsHoverContentHovering(false)}>
    <Link to="/web_application" >   <FontAwesomeIcon icon={faGlobe} />   Web App Development </Link>
    </p>
    <br/>
    <p className="text-gray-700">
        <FontAwesomeIcon icon={faMobileAlt} /> Mobile App Development
    </p>
    <br/>
    <p className="text-gray-700">
        <FontAwesomeIcon icon={faPalette} /> UI/UX Design
    </p>
</div>
      </div>
  </div>
  
      )}

{(isHovering1 || isHoverContentHovering1) && (
        <div 
          className="hover-content text-center sticky z-10  ml-10 mr-10" style={{top:'79px'}}
          onMouseEnter={() => setIsHoverContentHovering1(true)}
          onMouseLeave={() => setIsHoverContentHovering1(false)}
        >
          
          <div className="bg-gray-200 p-4 text-center absolute z-50 top-30 w-full">
            <h2 className="text-xl font-bold mb-2">Our Products</h2>
            <p className="text-gray-700">AAAAAA</p>
            <p className="text-gray-700">BBBBBB</p>
            <p className="text-gray-700">CCCCCCC</p>
          </div>
        </div>
      )}
    </>
  );
};

export default NavBar;
