import React from "react";
import TEST from "../assets/HG.webp";
import { Link } from "react-router-dom"; // Assuming you're using React Router for navigation
import Footer from "./footer";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";

import webAppImage from "../assets/wp.jpg";
import mobileAppImage from "../assets/mobile.jpg";
import uiUxImage from "../assets/yu.jpg";

const Home = () => (
  <div>
    <style>
      {`
        @keyframes fade-in {
          from {
            opacity: 0;
            transform: translateY(20px);
          }
          to {
            opacity: 1;
            transform: translateY(0);
          }
        }


        .animate-fade-in {
          animation: fade-in 1s ease-out;
        }
      `}
    </style>
    <div
      className="navbar  flex flex-col sm:flex-row  max-h-screen animate-fade-in"
      style={{ height: "535px", backgroundColor: "rgb(1, 6, 27)" }}
      // backgroundColor: "rgb(5,25,37)"
    >
      <div className=" sm:w-7/12  sm:mb-0">
        <img
          src={TEST}
          alt=""
          style={{
            height: "535px",

            maskImage:
              "linear-gradient(to left, transparent, black 20%, black 90%, transparent)",
            WebkitMaskImage:
              "linear-gradient(to left, transparent, black 50%, black 90%, transparent)",
          }}
        />
      </div>

      <div className="w-full sm:w-5/12 flex   sm:flex-col justify-center sm:items-center">
        <h2 className="text-3xl sm:text-4xl font-semibold mb-4 text-white font-serif">
          Innovative Solutions for a Digital World
        </h2>
        <p className="text-base sm:text-lg leading-relaxed text-white  pr-14">
          Welcome to Five Y Technologies! We are a leading software firm located
          in the vibrant city of Madurai. With a dynamic team of 28 adept
          developers and testers, we specialize in crafting innovative solutions
          for a digital world. Our blend of youthful energy and seasoned
          expertise ensures that we deliver groundbreaking solutions tailored to
          our clients' unique requirements.
        </p>
        <p className="mt-4  text-white font-serif pr-8">
          For deeper insights, explore our website at
          <a
            href="https://fiveytech.com/"
            className="text-blue-300 text-lg"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://fiveytech.com
          </a>
          .
        </p>
        <div className="mt-8 flex items-center">
          <button className="bg-blue-200  py-2 px-4 rounded-full hover:bg-blue-600 focus:outline-none focus:shadow-outline-blue active:bg-blue-800">
            Get Started
          </button>
        </div>
      </div>
    </div>

    {/* <div style={{ boxShadow: 'inset 0 0 10px 5px rgba(0, 0, 0, 1)' }}> */}
    <div>
      <div className="container mx-auto px-2 pt-6 font-serif ">
        <h1 className="text-2xl font-bold mb-4 text-center ">OUR SERVICES</h1>

        <p className="text-lg mb-8">
          Five Y Technologies, We strive to offer client-friendly services with
          transparent and easy-to-understand quotes. Our team is dedicated to
          providing top-notch solutions tailored to your needs.
        </p>

        <div className="flex flex-wrap -mx-4">
          <div
            className="w-full md:w-1/3 px-4 mb-8"
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <div
              className="bg-white shadow-lg rounded-lg overflow-hidden"
              style={{ flex: "1 1 auto" }}
            >
              <img
                src={webAppImage}
                alt="Web Application"
                className="w-full h-64 object-cover object-center"
              />
              <div className="p-4">
                <h2 className="text-xl font-semibold mb-2">
                  Web Application Development
                </h2>
                <p className="text-gray-700">
                  Our web application development services are designed to meet
                  the unique requirements of your business. Whether you need a
                  simple website or a complex web platform, we have the
                  expertise to deliver.
                </p>
              </div>

              <div className="text-center mb-6">
                <Link
                  to="/web_application"
                  className="bg-violet-700 text-white py-4 px-6 rounded-full hover:bg-blue-600 focus:outline-none focus:shadow-outline-blue active:bg-blue-800"
                >
                  <FontAwesomeIcon icon={faArrowRight} className="text-white" />
                </Link>
              </div>
            </div>
          </div>

          <div className="w-full md:w-1/3 px-4 mb-8">
            <div className="bg-white border-2 shadow-lg rounded-lg overflow-hidden">
              <img
                src={uiUxImage}
                alt="UI/UX Design"
                className="w-full h-64 object-cover object-center"
              />
              <div className="p-4">
                <h2 className="text-xl font-semibold mb-2">UI/UX Design</h2>
                <p className="text-gray-700">
                  User experience is at the heart of everything we do. Our UI/UX
                  design services focus on creating visually stunning interfaces
                  that are intuitive and easy to navigate.
                </p>
                <br />
                {/* <Link to="/web_application" className="mt-4 block w-full text-center bg-blue-500 text-white py-2 px-4 rounded-full hover:bg-blue-600 focus:outline-none focus:shadow-outline-blue active:bg-blue-800">
                Explore
              </Link> */}
              </div>
              <div className="text-center mb-6">
                <Link
                  to="/"
                  className="bg-violet-700 text-white py-4 px-6 rounded-full hover:bg-blue-600 focus:outline-none focus:shadow-outline-blue active:bg-blue-800"
                >
                  <FontAwesomeIcon icon={faArrowRight} className="text-white" />
                </Link>
              </div>
            </div>
          </div>

          <div className="w-full md:w-1/3 px-4 mb-8">
            <div className="bg-white shadow-lg rounded-lg overflow-hidden">
              <img
                src={mobileAppImage}
                alt="Mobile App"
                className="w-full h-64 object-cover object-center"
              />
              <div className="p-4">
                <h2 className="text-xl font-semibold mb-2">
                  Mobile App Development
                </h2>
                <p className="text-gray-700">
                  In the fast-paced world of mobile technology, having a
                  standout mobile app is essential for success. Our team
                  specializes in creating intuitive and high-performing mobile
                  applications for iOS and Android platforms.
                </p>
                {/* <Link to="/web_application" className="mt-4 block w-full text-center bg-blue-500 text-white py-2 px-4 rounded-full hover:bg-blue-600 focus:outline-none focus:shadow-outline-blue active:bg-blue-800">
                Explore
              </Link> */}
              </div>
              <div className="text-center mb-6">
                <Link
                  to="/"
                  className="bg-violet-700 text-white py-4 px-6 rounded-full hover:bg-blue-600 focus:outline-none focus:shadow-outline-blue active:bg-blue-800"
                >
                  <FontAwesomeIcon icon={faArrowRight} className="text-white" />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <Footer />
  </div>
);

export default Home;
