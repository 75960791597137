import React from 'react';
import LottieAnimation from './animate';
import { Link } from 'react-router-dom'; // Assuming you're using React Router for navigation
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight  } from "@fortawesome/free-solid-svg-icons";
import pharmacy from '../assets/dfs.webp'
import hs from '../assets/bgimage.jpg'
import dental from '../assets/ytu.jpg'
import Frontend from '../assets/FE.jpg'
import Backend from '../assets/BE.avif'


const WebApp = () => {
  return (
    <div className="max-w-screen-lg mx-auto px-4 py-8">
      {/* Heading */}
      <div className="text-center mb-8">
        <h1 className="text-4xl font-bold text-gray-800">
          Web Application Development
        </h1>
        <p className="text-lg text-gray-600">
          Integrated and Scalable web development solutions, easily accessible from anywhere
        </p>
      </div>
      
      <div className="flex items-center justify-between">
        <div className="w-1/2 pr-4">
        <LottieAnimation />
        </div>
        
        <div className="w-1/2 pl-4">
          <h2 className="text-2xl font-bold text-gray-800 mb-4">What We Offer</h2>
          <p className="text-gray-700 mb-4">
            Five Y Technologies, over 3+ years of experience in full-stack web development offers reliable and secure web application development services.
            We deliver the services as per co-ordinating with your business methods & your best practices. Our collaborative approach helps us to better understand your web development needs to deliver the successful end results. We serve varieties of services with our hands-on technologies.
          </p>
          <ul className="text-gray-700">
            <li className="mb-2">Back-End Development</li>
            <li className="mb-2">Front-End Development</li>
          </ul>
        </div>
      </div>
      <div className="flex items-center justify-between">
      
        <div className="w-1/2 pr-4">
          <h2 className="text-2xl font-bold text-gray-800 mb-4">Back-End Development Services</h2>
          <p>
          Compile your complex and cumbersome functions with our expert team of back-end developers.
          </p>
          <p className="text-gray-700 mb-4">
          Technologies has left remarkable footprints in development of back-end code with using our hands-on tools We are serving as backbone development experts of every front-end code. Our most prominent and powerful back-end services will help you to solve your cumbersome development functionalities.

Our key priorities to serve in back-end development are mobile app rest API development, security assessment, the accuracy of data management, data redundancy and most importantly performance of the web application. We have our full hands-on many back-end technologies, though we have adopted the most emerging technology trend, we acquired our expertise in:
          </p>
          <ul className="text-gray-700">
            <li className="mb-2">Node js</li>
            <li className="mb-2">EXpress js</li>
          </ul>
        </div>
        <div className="w-1/2 pl-4" >
       <img src={Backend} alt='logo' style={{height: 300,
    width: 500}}></img>
        </div>
      </div>
      <div className="flex items-center justify-between">
        <div className="w-1/2 pr-4">
        <img src={ Frontend} alt='logo' style={{height: 300,
    width: 500}}></img>
        </div>
        
        <div className="w-1/2 pl-4">
          <h2 className="text-2xl font-bold text-gray-800 mb-4">Front-End Development Services</h2>
          <p>Magnify your user experience through responsive and interactive designs with emerging front-end technologies.</p>
          <p className="text-gray-700 mb-4">
          Benefit from the vast pool of front-end development tools used by our team of experts. EliteEvince Technologies helps you to deliver top-notch, highly-tailored front-end solutions that coordinate with your business niche. As a leading offshore front-end development company, we have achieved satisfactory delivery to the clients with our broader agile and scrum approach. It helped them to stand out of from the clutter. Our core expertise in front-end development is in:
          </p>
          <ul className="text-gray-700">
            <li className="mb-2">React JS</li>
            <li className="mb-2">Angular</li>
          </ul>
        </div>
      </div>

      <div className="text-center mb-8">
        <h1 className="text-4xl font-bold text-gray-800">
         Web Projects
        </h1>
      </div>
      <div className="flex flex-wrap -mx-4">
        <div className="w-full md:w-1/3 px-4 mb-8" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
  <div className="bg-white shadow-lg rounded-lg overflow-hidden" style={{ flex: '1 1 auto' }}>
    <img src={hs} alt="Web Application" className="w-full h-64 object-cover object-center" />
    <div className="p-4">
      <h2 className="text-xl font-semibold mb-2">Hospital Management System</h2>
      <p className="text-gray-700">
       Workflow Management
      </p>
      {/* <p>Technology Used:</p>
      <p>
        React JS, Node JS, Express JS & My sql
      </p> */}
      <Link to="/hms" className="mt-4 block w-full text-center bg-blue-500 text-white py-2 px-4 rounded-full hover:bg-blue-600 focus:outline-none focus:shadow-outline-blue active:bg-blue-800">
      <FontAwesomeIcon icon={faArrowRight} className="text-white" />

              </Link>
    </div>
    
    {/* <div className="text-center">
      <Link to="/web_application" className="bg-blue-500 text-white py-4 px-4 rounded-full hover:bg-blue-600 focus:outline-none focus:shadow-outline-blue active:bg-blue-800" >
        <FontAwesomeIcon icon={faArrowRight} className="text-white" />
      </Link>
    </div> */}
  </div>
</div>


        <div className="w-full md:w-1/3 px-4 mb-8">
          <div className="bg-white shadow-lg rounded-lg overflow-hidden">
            <img src={dental} alt="Mobile App" className="w-full h-64 object-cover object-center" />
            <div className="p-4">
            <h2 className="text-xl font-semibold mb-2">Dental Clinic</h2>
      <p className="text-gray-700">
       Workflow Management
      </p>
      <Link to="/web_application" className="mt-4 block w-full text-center bg-blue-500 text-white py-2 px-4 rounded-full hover:bg-blue-600 focus:outline-none focus:shadow-outline-blue active:bg-blue-800">
      <FontAwesomeIcon icon={faArrowRight} className="text-white" />

              </Link>      
            </div>
          </div>
        </div>

        <div className="w-full md:w-1/3 px-4 mb-8">
          <div className="bg-white shadow-lg rounded-lg overflow-hidden">
            <img src={pharmacy} alt="UI/UX Design" className="w-full h-64 object-cover object-center" />
            <div className="p-4">
            <h2 className="text-xl font-semibold mb-2">Pharmacy Medical</h2>
      <p className="text-gray-700">
       Workflow Management
      </p>
      <Link to="/web_application" className="mt-4 block w-full text-center bg-blue-500 text-white py-2 px-4 rounded-full hover:bg-blue-600 focus:outline-none focus:shadow-outline-blue active:bg-blue-800">
      <FontAwesomeIcon icon={faArrowRight} className="text-white" />

              </Link>     
            </div>
          </div>
        </div>
        <div className="w-full md:w-1/3 px-4 mb-8">
          <div className="bg-white shadow-lg rounded-lg overflow-hidden">
            <img src={pharmacy} alt="UI/UX Design" className="w-full h-64 object-cover object-center" />
            <div className="p-4">
            <h2 className="text-xl font-semibold mb-2">Stock Management System</h2>
      <p className="text-gray-700">
       Workflow Management
      </p>
      <Link to="/web_application" className="mt-4 block w-full text-center bg-blue-500 text-white py-2 px-4 rounded-full hover:bg-blue-600 focus:outline-none focus:shadow-outline-blue active:bg-blue-800">
      <FontAwesomeIcon icon={faArrowRight} className="text-white" />

              </Link>     
            </div>
          </div>
        </div>
      </div>
      
    </div>
  );
};

export default WebApp;
