import React from 'react';
import TEST from '../assets/hms.png';
import pic from '../assets/jk.jpg';
import hms from '../assets/d.jpg';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import Backend from '../assets/dashboard.PNG'
import Register from '../assets/registration.PNG'
import Billing from '../assets/billing.PNG'
import Bill from '../assets/bill.PNG'
import S from '../assets/statics.PNG';
import D from '../assets/daily reports.PNG';
import Doc from '../assets/doctor.PNG';
import pre from '../assets/pres.PNG'
import Footer from './footer';


const HMS = () => {
  return (
    <div className="">
      <style>
        {`
          .carousel-slider {
            height: 400px !important;
          }
        //   .legend {
        //     background: rgba(0, 0, 0, 0.7);
        //     color: black;
        //     padding: 10px;
        //     position: absolute;
        //     top: 0;
        //     left: 0;
        //     width: 100%; height:50px;
        //   }
        `}
      </style>
      
      <div className="text-center">
        <h1 className="text-4xl font-bold text-gray-800">
          Hospital Management System
        </h1>
        <p className="text-lg text-gray-600">
          Digital Hospital is the most user-friendly and reliable Hospital
          Management Information System (HMIS)
        </p>
      </div>
      <div>
        <Carousel>
          <div>
          <img src={hms} alt="Image 2" />
            <div className="legend">
              <h2>Related Positive Content for Hospital Management Project</h2>
              <p>Content goes here xxxxx...</p>
            </div>
          </div>
          <div >
            <img src={pic} alt="Image 2" />
            <div className="legend">
              <h2>Related Positive Content for Hospital Management Project</h2>
              <p>Content goes here yyyyy...</p>
            </div>
          </div>

          <div >
          <img src={TEST} alt="Image 1" />
            <div className="legend">
              <h2>Related Positive Content for Hospital Management Project</h2>
              <p>Content goes here yyyyy...</p>
            </div>
          </div>
          {/* Add more Carousel items for additional slides */}
        </Carousel>
      </div>
      
      <div className="flex  bg-gray-100">

      <div className="bg-white rounded-lg shadow-lg p-10 m-10 content">
  <h1 className="text-4xl font-bold mb-6 text-center">Digital Hospital</h1>
  <p className="text-base text-gray-700 leading-relaxed">
    A diversified Hospital Management System for healthcare setups to ensure effective patient care and efficient management of day-to-day activity in a hospital setup.
    <br/><br/>
    Five Y Technologies, a pioneer in the development of software applications for the healthcare domain, presents the ultimate product for the overall management of Hospitals.
    <br/><br/>
    HMS is packed with assorted features and is easy to use and also ensures the security of the data.
    <br/><br/>
    HMS is the most advanced software solution specially designed for Corporate / Semi-corporate Hospitals / Nursing homes. HMS is a Windows-based application with backend integration with SQL Server, which provides great ease of use and also ensures the security of the data. HMS includes various modules such as OPD, IPD, and Pathology along with interfacing with a variety of biochemistry analyzers.
    
  </p>
</div>

      </div>

  
      <div className="flex items-center p-10">
        {/* Left side (picture) */}
        <div className="w-1/2 ">
        <img src={Backend} alt='logo' style={{height: 330,
    width: 580}}></img>
        </div>
        
        {/* Right side (content) */}
        <div className="w-1/2 ">
          <h2 className="text-2xl font-bold text-gray-800 mb-4">Dashboard</h2>
          <p>Our creative UI/UX designers created an interactive dashboard for the hospital management system and our development team converted it into a highly user-friendly platform using trending technologies.</p>
          <br/>
          <p className="text-gray-700 mb-4">
          

We made sure that the admin covers everything right from the doctor list, to the patient list to add a doctor, add an appointment, and add patient options. Over the dashboard only, the user can get details of total appointments, total patients, and average patients per doctor.


          </p>
          <p>
          Apart from this, in the midsection of the dashboard for a hospital management system, users can find Patient Details, Date, and Time of the Appointment, and the Doctor with whom the appointment is booked.
          </p>
        
        </div>
      </div>
      
      <div className="flex items-center p-10 bg-gray-100">
        <div className="w-1/2 pr-10">
          <h2 className="text-2xl font-bold text-gray-800 mb-4">Patient Registration and Management:</h2>
          <p>Our system simplifies patient registration by offering an intuitive interface for efficient data entry and record-keeping. With features such as demographic data collection, patients can provide their information easily, reducing registration time and minimizing errors.</p>
          <br/>
          <p className="text-gray-700 mb-4">
          Medical history storage allows healthcare providers to access essential patient information quickly, enabling informed decision-making and personalized care.
          </p>
          <p>
          Insurance information management ensures accurate billing and claims processing, streamlining administrative tasks and improving revenue cycle management. By prioritizing accurate and accessible patient information, our system enhances the delivery of quality care, enabling healthcare providers to make well-informed decisions and provide personalized treatment plans.
          </p>
        
        </div>

        <div className="w-1/2 pl-6">
        <img src={Register} alt='logo' style={{height: 330,
    width: 580}}></img>
        </div>
      </div>

      

      <div className="flex items-center p-10">
        {/* Left side (picture) */}
        <div className="relative w-1/2 p-10">
  <img src={Billing} alt='logo' className="absolute bottom-0 left-0" style={{height: 230, width: 430}} />
  <img src={Bill} alt='logo' className="absolute top-0 right-0 pr-10" style={{height: 230, width: 430}} />

</div>


        
        {/* Right side (content) */}
        <div className="w-1/2 ">
          <h2 className="text-2xl font-bold text-gray-800 mb-4">Billing and Payment Processing:
</h2>
          <p>Our system automates billing tasks by streamlining the entire process, from generating invoices to processing payments, reducing manual intervention and saving time. With features like electronic invoicing, hospitals can send invoices to patients electronically, accelerating the billing process and reducing paper waste.</p>
          <br/>
          <p className="text-gray-700 mb-4">
          Insurance claim submission is simplified through our system, allowing healthcare providers to submit claims electronically to insurance companies, resulting in faster reimbursement and improved cash flow.
          </p>
          <p>
          Online payment options provide convenience to patients, allowing them to settle their bills securely from anywhere, at any time. By automating billing tasks and offering electronic invoicing, insurance claim submission, and online payment options, our system helps hospitals minimize billing errors, improve revenue cycle management, and enhance overall financial efficiency.
          </p>
        
        </div>
      </div>

      <div className="flex items-center p-10 bg-gray-100">
        <div className="w-1/2 pr-10">
          <h2 className="text-2xl font-bold text-gray-800 mb-3">Doctor Module:
</h2>
          <p className='mb-3'>In the Patient List section, you will find the list of patients who have visited the hospital for diagnosis. The List includes the Patient’s Name, Age, Phone Number, Address, City, and also the number of visits.</p>
         
          <p className="text-gray-700 mb-3">
          Also, as the user clicks on the arrow present in the right corner, one can find the complete details about the patient such as Symptoms, Diagnosis, Prescription, Attachments, and Notes. The patient details also include attachments like reports and prescriptions.
          </p>
          <p className='mb-3'>
          Users can share the entire patient details with other doctors and also download the details. “Add Patient” options enable users to easily add all the Patient details and attach documents.
          </p>         
        </div>

       
        {/* <div className="relative w-1/2 p-10">
  <img src={S} alt='logo' className="absolute bottom-0 left-0" style={{height: 230, width: 430}} />
  <img src={D} alt='logo' className="absolute top-0 right-0 pr-10" style={{height: 230, width: 430}} />

</div> */}
  <div className="w-1/2 pl-6">
        <img src={Doc} alt='logo' style={{height: 330,
    width: 580}}></img>
    </div>
       
      </div>

      <div className="flex items-center p-10">
        {/* Left side (picture) */}
        <div className="w-1/2 ">
        <img src={pre} alt='logo' style={{height: 330,
    width: 580}}></img>
        </div>
        
        {/* Right side (content) */}
        <div className="w-1/2 ">
          <h2 className="text-2xl font-bold text-gray-800 mb-4">E-prescription</h2>
          <p>E-Prescription is the computer-based electronic generation, transmission, and filling of a medical prescription, taking the place of paper and faxed prescriptions. 
            </p>   <br/>
          <p className="text-gray-700 mb-4">
          E-prescribing allows a physician, physician assistant, pharmacist, or nurse practitioner to use digital prescription software to electronically transmit a new prescription or renewal authorization to a community or mail-order pharmacy. It outlines the ability to send error-free, accurate, and understandable prescriptions electronically from the healthcare provider to the pharmacy. 
          </p>
          <p>
          E-prescribing is meant to reduce the risks associated with traditional prescription script writing. It is also one of the major reasons for the push for electronic medical records. By sharing medical prescription information, e-prescribing seeks to connect the patient's team of healthcare providers to facilitate knowledgeable decision making.  </p>
        
        </div>
      </div>

      <div className="flex items-center p-10 bg-gray-100">
        <div className="w-1/2 pr-10">
          <h2 className="text-2xl font-bold text-gray-800 mb-3">Reporting and Analytics:
</h2>
          <p className='mb-3'>Our system offers comprehensive reporting and analytics capabilities, empowering hospitals with valuable insights into their operations and performance.</p>
         
          <p className="text-gray-700 mb-3">
          Customizable reports allow healthcare facilities to tailor data analysis to their specific needs, extracting meaningful information and facilitating data-driven decision-making. 
          </p>
          <p className='mb-3'>
          Performance dashboards provide at-a-glance visibility into key metrics and trends, enabling stakeholders to monitor performance in real-time and track progress towards organizational goals.
          </p>
          <p className='mb-3'> 
          Data visualization tools transform complex data sets into easy-to-understand charts and graphs, enhancing data interpretation and facilitating communication.
          </p>
          <p  className='mb-3'>
          By monitoring key performance indicators and identifying trends, our system helps hospitals proactively address operational challenges, capitalize on opportunities, and improve overall performance. With robust reporting and analytics capabilities, our system empowers healthcare organizations to make informed decisions and drive continuous improvement in patient care delivery.
          </p>
         
        </div>

       
        <div className="relative w-1/2 p-10">
  <img src={S} alt='logo' className="absolute bottom-0 left-0" style={{height: 230, width: 430}} />
  <img src={D} alt='logo' className="absolute top-0 right-0 pr-10" style={{height: 230, width: 430}} />

</div>
       
      </div>
     <Footer/>
    </div>
  );
};

export default HMS;
