import React from "react";
import logo from '../assets/logo.png';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {  faMapMarkerAlt, faEnvelope , faPhone } from "@fortawesome/free-solid-svg-icons";

import { faFacebook, faLinkedin, faInstagram, faYoutube, faTwitter } from "@fortawesome/free-brands-svg-icons";

const Footer = () =>(

<div className=" mx-auto  py-8 text-white" style={{  backgroundColor: "rgb(1, 6, 27)"}}>
<div style={{  backgroundColor: "rgb(9, 30, 62)"}}>
<div className="flex flex-row pt-10" >
  <div>
        
        </div>
        <div className="w-1/5 ml-10">
            <div className="mb-4">
                <h2 className="mb-2 text-bold text-2xl">Services</h2>
                <ul>
                    <li>Web Development</li>
                    <li>App Development</li>
                    <li>UIUX Design</li>
                </ul>
            </div>
        </div>
        <div className="w-1/5">
            <div className="mb-4">
                <h2 className="mb-2  text-2xl">Technologies</h2>
                <ul>
                    <li>Java Script </li>
                    <li>React JS</li>
                    <li>Tailwind , Bootstrap</li>
                    <li>Node JS  , Express JS</li>    
                    <li>MY SQL   , Mongo DB</li>
                    <li>Python</li>
                    <li>AWS</li>
                   
                </ul>
            </div>
        </div>
        <div className="w-1/5">
            <div className="mb-4">
                <h2 className="mb-2  text-2xl">Company</h2>
                <ul>
                    <li>About Us</li>
                    <li>Partner with us</li>
                    <li>Careers</li>
                    <li>Contact us</li>
                </ul>
            </div>
        </div>
        {/* <div className="w-1/6">
            <div className="mb-4">
                <h2 className="mb-2">Contact Us</h2>
                <ul>
                    <li>info@fiveytech.com</li>
                    <li>App Development</li>
                    <li>UIUX Design</li>
                </ul>
            </div>
        </div> */}
        <div className="w-1/6">
            <div className="mb-4">
              <div >
              <img src={logo} alt="UI/UX Design" className="w-full object-cover object-center" />

              </div>

                {/* Below Content */}
                {/* <div className=" mb-2">Five Y Tech is a leading Full Stack Development company based in India. We are providing Web & Mobile App Development solutions.</div> */}
                <div className=" mb-4 mt-4" style={{width:'350px'}}>
                Your digital journey begins here, where pixels meet purpose and code turns dreams into reality.
                </div>
                {/* Social Icons */}
                <div className="flex flex-row ">
<div className="rounded-full border-2 border-gray-300 p-1 mr-2 hover:bg-blue-500 hover:border-blue-500 transition duration-300 ease-in-out">
<a href="https://www.facebook.com/people/Five-Y-Technologies-Decoders/61554694599414/?mibextid=ZbWKwL" className="mr-4" target="_blank" rel="noopener noreferrer">
                    <FontAwesomeIcon icon={faFacebook}  className="w-10 h-6 " />
                  </a></div>
<div className="rounded-full border-2 border-gray-300 p-1 mr-2 hover:bg-blue-500 hover:border-blue-500 transition duration-300 ease-in-out">
<a href="https://www.linkedin.com/company/fiveytech/" className="mr-4" target="_blank" rel="noopener noreferrer">
                    <FontAwesomeIcon icon={faLinkedin} className="w-10 h-6" />
                  </a></div>
<div className="rounded-full border-2 border-gray-300 p-1 mr-2 hover:bg-blue-500 hover:border-blue-500 transition duration-300 ease-in-out">
<a href="https://www.instagram.com/fiveytechnologies/?igshid=ODA1NTc5OTg5Nw%3D%3D" className="mr-4" target="_blank" rel="noopener noreferrer">
                    <FontAwesomeIcon icon={faInstagram} className="w-10 h-6 " />
                  </a>
  {/* <FontAwesomeIcon icon={faInstagram} className="w-10 h-6 " /> */}
</div>
<div className="rounded-full border-2 border-gray-300 p-1 mr-2 hover:bg-blue-500 hover:color-white hover:border-blue-500 transition duration-300 ease-in-out">
<a href="https://www.youtube.com/@FiveYTechnologies" className="mr-4" target="_blank" rel="noopener noreferrer">
                    <FontAwesomeIcon icon={faYoutube}  className="w-10 h-6 " />
                  </a>
</div>
<div className="rounded-full border-2 border-gray-300 p-1 mr-2 hover:bg-blue-500 hover:border-blue-500 transition duration-300 ease-in-out">
<a href="https://twitter.com/fiveytech?t=dps8Wn6cdWZw2HIM5PNckg&s=08" className="mr-4" target="_blank" rel="noopener noreferrer">
                    <FontAwesomeIcon icon={faTwitter}  className="w-10 h-6 " />
                  </a>
</div>
</div>
            </div>
        </div>
  </div>  
  <div className="flex justify-center p-5">
<div className="flex items-center">
  {/* <p className="text-gray-500 text-sm">&copy; 2024 Five Y Technologies All Rights Reserved</p> */}
  <div className="flex items-center mr-4">
        <FontAwesomeIcon icon={faMapMarkerAlt} className="mr-1" />
        <span>K.K.Nagar, Madurai-625020</span>
      </div>
      <div className="flex items-center mr-4">
        <FontAwesomeIcon icon={faPhone} className="mr-1" />
        <span>+91 6369070815</span>
      </div>
      <div className="flex items-center">
        <FontAwesomeIcon icon={faEnvelope} className="mr-1" />
        <span>info@fiveytech.com</span>
      </div>
</div>
</div> 
</div>
  
  <div className="flex justify-center border-t pt-4 ">
<div className="flex items-center">
  <p className="text-gray-500 text-sm">&copy; 2024 Five Y Technologies All Rights Reserved</p>
</div>
</div>   
</div>
);

export default Footer;















